const axios = require("axios");
import { config } from "../../config.js";
import { resetComentarios } from "../comentarios/comentarios.js";

$(window, document, undefined).ready(function () {
  $("input").blur(function () {
    var $this = $(this);
    if ($this.val()) $this.addClass("used");
    else $this.removeClass("used");
  });

  var $ripples = $(".ripples");

  $ripples.on("click.Ripples", function (e) {
    var $this = $(this);
    var $offset = $this.parent().offset();
    var $circle = $this.find(".ripplesCircle");

    var x = e.pageX - $offset.left;
    var y = e.pageY - $offset.top;

    $circle.css({
      top: y + "px",
      left: x + "px",
    });

    $this.addClass("is-active");
  });

  $ripples.on(
    "animationend webkitAnimationEnd mozAnimationEnd oanimationend MSAnimationEnd",
    function (e) {
      $(this).removeClass("is-active");
    }
  );
  $(".toggle-password").click(function () {
    $(this).toggleClass("fa-eye fa-eye-slash");
    if ($("#pwd").attr("type") == "password") {
      $("#pwd").attr("type", "text");
    } else {
      $("#pwd").attr("type", "password");
    }
  });

  $("#login").on("click", function () {
    $("#loaderLogin").show();
    login($("#user").val(), $("#pwd").val());
  });
});

async function login(usr, pwd) {
  try {
    const parametros = {
      password: pwd,
    };

    let response = await axios.post(
      `${config.backend}/api/executiveDashboard/login/${config.aplicationId}/${usr}/${localStorage.sociedad}`,
      parametros
    );
    if (response.data.status == "not") throw new Error(response.data.mensaje);

    signIn(response.data);
  } catch (error) {
    alert(error);
  } finally {
  }
}

//****************************INFORMACION USUARIO AUTENTICATION ****************************************** */
async function obtenerDatosAutenticacion(usr) {
  try {
    let response = await axios.get(
      `${config.backend}/api/executiveDashboard/userdata/${config.aplicationId}/${usr}/${localStorage.sociedad}`,
      {
        headers: {
          "X-API-TOKEN": localStorage.accessToken,
        },
      }
    );
    if (response.data.status == "not") throw new Error(response.data.mensaje);

    return response.data;
  } catch (error) {
    console.log(error);
    alert(
      "Se presento un detallle durante la carga de esta lista de reproducción, favor de reportarlo a: soportedev@firstmajestic.com"
    );
    window.location.assign("./index.html");
  } finally {
  }
  {
  }
}

function getQueryVariable(variable, type) {
  //type depende de si es la direccion del url generada desde cognito o cuando es generado por ela app
  var query =
    type == "hash"
      ? window.location.hash.substring(1)
      : window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return false;
}

async function validarAcceso() {
  const access_token = getQueryVariable("access_token", "hash");
  const id_token = getQueryVariable("id_token", "hash");
  const token_type = getQueryVariable("token_type", "hash");
  const refresh_token = getQueryVariable("refresh_token", "hash");
  var infoUsuario;

  if (access_token != false && access_token != "undefined") {
    localStorage.setItem("accessToken", access_token);
    localStorage.setItem("idToken", id_token);
    localStorage.setItem("tokenType", token_type);
    localStorage.setItem("refreshToken", refresh_token);
    window.location.href = config.redirect_uri;
  } else {
    if (
      localStorage.getItem("accessToken") != false &&
      localStorage.getItem("accessToken") != undefined
    ) {
      infoUsuario = await informacionUsuario(
        localStorage.getItem("tokenType"),
        localStorage.getItem("accessToken")
      );
    } else {
      window.location.href = `https://${config.dominio}.auth.${config.region}.amazoncognito.com/oauth2/authorize?response_type=token&client_id=${config.client_id}&redirect_uri=${config.redirect_uri}&state=STATE`;
    }
  }


  return infoUsuario;
}

//****************************INFORMACION USUARIO COGNITO AD ****************************************** */
async function informacionUsuario(tokenType, accessToken) {
  try {
    //console.log(accessToken);
    var configData = {
      method: "get",
      url: `https://${config.dominio}.auth.${config.region}.amazoncognito.com/oauth2/userInfo`,
      headers: {
        Authorization: `${tokenType} ${accessToken}`,
      },
    };

    const response = await axios(configData);
    if (response == undefined){
      window.location.href = `https://${config.dominio}.auth.${config.region}.amazoncognito.com/oauth2/authorize?response_type=token&client_id=${config.client_id}&redirect_uri=${config.redirect_uri}&state=STATE`;

    }

    return response;
  } catch (error) {
    console.log(error);
    window.location.href = `https://${config.dominio}.auth.${config.region}.amazoncognito.com/oauth2/authorize?response_type=token&client_id=${config.client_id}&redirect_uri=${config.redirect_uri}&state=STATE`;

  }
}
async function getUser(code) {
  $.ajax({
    async: false,
    dataType: "json",
    url: `https://${config.dominio}.auth.${config.region}.amazoncognito.com/oauth2/token`,
    data: {
      code: code,
      client_id: config.client_id,
      redirect_uri: config.redirect_uri,
      grant_type: "authorization_code",
    },
    type: "POST",
    contentType: "application/x-www-form-urlencoded; charset=utf-8",
    crossDomain: true,
    cache: true,

    success: function (data) {
      console.log(data);
      var dataconfig = {
        method: "get",
        url: `https://${config.dominio}.auth.${config.region}.amazoncognito.com/oauth2/userInfo`,
        headers: {
          Authorization: `${data.token_type} ${data.access_token}`,
          Cookie:
            'XSRF-TOKEN=91494bc1-e628-463e-adcf-a2b2c1768706; cognito-fl="W10="',
        },
      };

      axios(dataconfig)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          getUserData(response.data.username);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    error: function (jqXHR, exception, errorstr) {
      console.log(jqXHR);
      alert(errorstr);
    },
  });
}

function loadImage(id) {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.addEventListener("load", (e) =>
      resolve(`${config.bucketFotoPerfil}${id}.jpg`)
    );
    img.addEventListener("error", () => {
      reject(`${config.bucketFotoPerfil}blank-face.jpg`);
    });
    img.src = `${config.bucketFotoPerfil}${id}.jpg`;
  });
}
//****************************SING IN ****************************************** */
async function signIn(data) {
  const sociedad = localStorage.sociedad;

  $("#loaderLogin").hide();
  await localStorage.setItem("token", data.token);
  await localStorage.setItem("usuario", data.userName);
  await localStorage.setItem("idTrab", data.idTrab);
  await localStorage.setItem("nombreCompleto", data.nombreCompleto);
  await localStorage.setItem(
    "rolComentarios",
    data.roles.includes(config.rolAcceso.toLowerCase())
  );
  await localStorage.setItem(
    "rolConsulta",
    data.roles.includes(config.rolConsulta.toLowerCase())
  );
  await localStorage.setItem(
    "consolidated",
    data.roles.includes(config.rolAcceso.toLowerCase())
  );

  data.roles.push("*");
  localStorage.roles = data.roles
    .map((x) => {
      return x.toLowerCase();
    })
    .join(",");

  localStorage.fotoUsuario = await loadImage(data.idTrab)
    .then((img) => {
      return img;
    })
    .catch((dummy) => {
      return dummy;
    });
}

//****************************SING OUT ****************************************** */
async function singOut() {
  //await localStorage.clear();
  await localStorage.removeItem("usuario");
  await localStorage.removeItem("token");
  await localStorage.removeItem("consolidated");
  await localStorage.removeItem("rolComentarios");
  await localStorage.removeItem("rolConsulta");
  await localStorage.removeItem("roles");
  await localStorage.removeItem("nombreCompleto");
  await localStorage.removeItem("idTrab");
  await localStorage.removeItem("fotoUsuario");
  await localStorage.removeItem("sociedad");
  resetComentarios();
  window.location.href = `https://${config.dominio}.auth.${config.region}.amazoncognito.com/logout?client_id=${config.client_id}&logout_uri=${config.logout_uri}`;
}

export {
  getQueryVariable,
  validarAcceso,
  informacionUsuario,
  singOut,
  signIn,
  loadImage,
  obtenerDatosAutenticacion,
};
