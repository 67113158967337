
import '../node_modules/jquery-modal/jquery.modal.min.css';
import '../node_modules/jquery-modal/jquery.modal.min.js';
import './assets/portada.css'

import {config} from './config.js'
import {validarAcceso, informacionUsuario} from "./js/login/login.js";


var cualUnidad;
var code;

// function getQueryVariable(variable) {
//   // Estoy asumiendo que query es window.location.search.substring(1);
//   var query = window.location.hash.substring(1);
//   var vars = query.split("&");
//   for (var i = 0; i < vars.length; i++) {
//     var pair = vars[i].split("=");
//     if (pair[0] == variable) {
//       return pair[1];
//     }
//   }
//   return false;
// }

function listaReproduccion(unidad, periodo) {
  var urlActual = window.location.href.split("#")[0];

  const urlLista = "./dashboard.html?time" + new Date().getTime() + "&menu=" + urlActual + "&unidadSelected=" + unidad + "&periodo=" + periodo + "&idLista=";
  switch (unidad) {
    case "jerrittCanyon":
      window.location.href = periodo == "Monthly" ? urlLista + "37" : urlLista + "38";
      break;
    case "santaElena":
      window.location.href = periodo == "Monthly" ? urlLista + "10" : urlLista + "17";
      break;
    case "ermitano":
      window.location.href = periodo == "Monthly" ? urlLista + "35" : urlLista + "36";
      break;
    case "laEncantada":
      window.location.href = periodo == "Monthly" ? urlLista + "12" : urlLista + "21";
      break;
    case "sanMartin":
      window.location.href = periodo == "Monthly" ? urlLista + "9" : urlLista + "20";
      break;
    case "laParrilla":
      window.location.href = periodo == "Monthly" ? urlLista + "11" : urlLista + "19";
      break;
    case "sanDimas":
      window.location.href = periodo == "Monthly" ? urlLista + "13" : urlLista + "24";
      break;
    case "delToro":
      window.location.href = periodo == "Monthly" ? urlLista + "15" : urlLista + "18";
      break;
    case "laGuitarra":
      window.location.href = periodo == "Monthly" ? urlLista + "14" : urlLista + "22";
      break;
    case "consolidated":
      window.location.href = periodo == "Monthly" ? urlLista + "16" : urlLista + "25";
      break;
  }
}


$(async function  () {
   

    const infoUsuario = await validarAcceso()
    
    
    $("input[name='periodo']").on('change', function () {
      localStorage.setItem("cualPeriodo", $("input[name=periodo]:checked").val());
    });
    
    $("#btnSanDimas").on('click', function () {
      const unidad = "sanDimas";
      const periodo = $("input[name=periodo]:checked").val();
      listaReproduccion(unidad, periodo);
    });
    $("#btnLaParrilla").on('click', function () {
      const unidad = "laParrilla";
      const periodo = $("input[name=periodo]:checked").val();
      listaReproduccion(unidad, periodo);
    });
    
    $("#btnSantaElena").on('click', function () {
      const unidad = "santaElena";
      const periodo = $("input[name=periodo]:checked").val();
      listaReproduccion(unidad, periodo);
    });
    $("#btnErmitano").on('click', function () {
      const unidad = "ermitano";
      const periodo = $("input[name=periodo]:checked").val();
      listaReproduccion(unidad, periodo);
    });
    
    $("#jerrittCanyon, #santaElena, #ermitano, #laEncantada, #sanDimas, #laParrilla, #delToro, #sanMartin, #laGuitarra, #consolidated ").on('click', function () {
      const unidad = this.id;
      const periodo = $("input[name=periodo]:checked").val();
      listaReproduccion(unidad, periodo);
      // $("#selectionForm").modal();
    });
    
    $(".hint").on('click',(function () {
      const unidad = $(this).data("unidad");
     const  periodo = $("input[name=periodo]:checked").val();
      if (unidad == "unidadesDgo") {
        $("#selectionDgo").modal({
            show: 'false'
        });
      } else if (unidad == "unidadesSonora") {
        $("#selectionSonora").modal();
      } else {
        listaReproduccion(unidad, periodo);
      }
    }));
    
})

