import {config} from '../config.js'
async function loadImage(id) {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.addEventListener("load", (e) => resolve(`${config.bucketFotoPerfil}${id}.jpg`));
      img.addEventListener("error", () => {
        reject(`${config.bucketFotoPerfil}blank-face.jpg`);
      });
      img.src = `${config.bucketFotoPerfil}${id}.jpg`;
    });
  }


  export {loadImage}