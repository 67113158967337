// module variables
export const config = {
  "app_name": "OPERATIONS REPORT",
  "app_desc": "OPERATIONS REPORT  AND EXECUTIVE DASHBOARD",
   "backend": 'https://operations.firstmajestic.com',
  "sockets": 'https://d1ar83emq1f1f0.cloudfront.net',
  "bucket":'fm-operationreport-uploads',
  "rolConsulta":'consultaExecutiveDashboard',
  "rolAcceso":'executiveDashboard',
  "bucketFotoPerfil":'https://fm-imagen-personal.s3.ca-central-1.amazonaws.com/',
  "aplicationId":"4D04C3E2-AB35-4D85-A9D3-7C6B30ED6ED3",
  "dominio":'fms-bitamina-pro',
  "region":"ca-central-1",
  "client_id":"7u5fh1c248tla7nugepa844j6d",
  "redirect_uri":"https://operations.firstmajestic.com",
  "logout_uri": "https://operations.firstmajestic.com"
}