import io from "socket.io-client";
import { config } from "../config.js";
import { cargarcomentariosajax, insertarComentario } from "./comentarios/comentarios.js";
import {cargarcomentariosConsolidated } from "./comentarios/consolidatedComments.js";
import { singOut } from "./login/login.js";

var socket = io.connect(config.sockets);

socket.on("connect", function () {
  console.log("Conectado al servidor de sockets");
  if (localStorage.unidadSelected == "consolidated" && localStorage.getItem("consolidated") == "true") socket.emit("room", localStorage.idLista);
  else socket.emit("room", localStorage.idLista);
});
// socket.on("disconnect", function () {
//     alert ('no hay internet');
// })
//****************************  manejo de sockets ********************************** */

socket.on("refrescarLista", (salon) => {
  location.reload();
});

//**********************AQUI SE RECARGAN LOS COMENTARIOS QUE VENGAN DE OTRO USUARIO ***************************/
socket.on("cerrarSesion", function () {

 singOut()
});

socket.on("recargaComentarios", function (idContenido, idEtiqueta) {

  if ($(".active:eq(1)").data("idcontenidoagregado") == idContenido && idEtiqueta == $(".etiqueta span").attr("id"))
    cargarcomentariosajax(idContenido, idEtiqueta);
});
socket.on("eliminaComentario", function (id) {
  var objeto = $("#burbuja_" + id).closest('[class*="wrapperTema"]');
  if (
    $("#burbuja_" + id)
      .closest('[class*="wrapperTema"]')
      .children("div").length == 1
  )
    objeto.remove();
  $("#burbuja_" + id).remove();
  if ($("#comentariosGenerales").height() > $("#contenedorChatGeneral").height()) {
    $("#tituloFijo").show();
  } else $("#tituloFijo").hide();
});
//**********************AQUI SE RECARGAN LOS COMENTARIOS CONSOLIDADOS  QUE VENGAN DE OTRO USUARIO ************************/
socket.on("recargaComentariosConsolidated", function (idContenido, idEtiqueta, idContenidoBase) {
  if (
    $(".active:eq(1)").data("idcontenidobase") == idContenidoBase &&
    localStorage.unidadSelected == "consolidated" &&
    localStorage.getItem("consolidated") == "true"
  )
    cargarcomentariosConsolidated($(".active:eq(1)").data("idcontenidoagregado"));
});

socket.on("recargaTotalTema", function (idContenido, idEtiqueta, total) {
  
  console.log(`total de temas: ${total}`)
  var cualEtiqueta = $("#" + localStorage.sociedad + "_" + idEtiqueta + " span");
  cualEtiqueta.text(total);
  console.log(localStorage.sociedad )
  if (!$("#" + localStorage.sociedad + "_" + idEtiqueta).hasClass("etiqueta")) {
    $(cualEtiqueta).removeClass("blinkNotifica").addClass("blinkNotifica");
  }
});

export { socket };
