const axios = require('axios');
import {config} from '../../config.js'
import {loadImage} from '../loadImage.js'

async function cargarcomentariosConsolidated (idContenido) {
  try {
    $("#carouselExampleIndicators").carousel("pause");
    let response = await axios.get(
      `${config.backend}/api/executiveDashboard/obtenerComentariosConsolidated/${idContenido}/${$("#historialConsolidated").is(":checked") ? false : true}`, {
        headers: {
          "X-API-TOKEN": localStorage.accessToken,
        },
      }
    );
    if (response.data.message) throw new Error(response.data.message);
    muestraComentariosConsolidated(response.data, idContenido);
  } catch (error) {
    alert(error);
  } finally {
  }
};

async function muestraComentariosConsolidated  (result, idContenido) {
  // $('.img-circle').attr("src", localStorage.getItem("fotoUsuario"));
  $(".contenedor-loader").hide();
  $("#comentariosGenerales").show();
  $("#comentariosGenerales").html("");
  //$("#comentariosGenerales").html("");
  if (result.rowsAffected > 0) {
    $("#tituloFijo").show();
    $("#tituloFijo").html(result.recordset[0].EtiquetaNombre);
    var etiqueta = null;
    for (var i = 0; i < result.rowsAffected; i++) {
      var comentario = nl2br(result.recordset[i].comentario);

      let fotoUsuario = await loadImage(result.recordset[i].idTrab)
        .then((img) => {
          return img;
        })
        .catch((dummy) => {
          return dummy;
        });
      if (etiqueta != result.recordset[i].idEtiqueta) {
        $("#comentariosGenerales").append(`<div class='wrapperTema${result.recordset[i].idEtiqueta}'  id="${result.recordset[i].EtiquetaNombre}">
        <li class='tituloEtiquetaConsolidated' id='tema_${i}' >${result.recordset[i].EtiquetaNombre}</li></div>`);
        etiqueta = result.recordset[i].idEtiqueta;
      }
      if ((await localStorage.getItem("usuario")) == result.recordset[i].usuario) {
        $(".wrapperTema" + result.recordset[i].idEtiqueta).append(`
                                     <!-- Message to the right -->
                                     <div id='burbuja_${result.recordset[i].id}' class="direct-chat-msg right" style=" padding-left: 10px;   justify-content: flex-end; padding-right:10px;display: flex;flex-wrap: nowrap;">

                                       <!-- /.direct-chat-info-->



                                       <div class ="direct-chat-text" style=" float:right;     background: #4a89dc;">
                                         <span style="float:right; display:inline-block;">
                                                ${comentario}
                                         </span>
                                         <div class ="direct-chat-info clearfix">
                                         <span class ="direct-chat-timestamp pull-right">${result.recordset[i].fechaFormato}</span>
                                       </div>
                                       
                                       </div>
                                       <!-- /.direct-chat-text -->
                                       <img class ="direct-chat-img " src="${fotoUsuario}" >
                                     </div>
         `);
      } else {
        $(".wrapperTema" + result.recordset[i].idEtiqueta).append(`
                                         <!--Message to the left-->
                                       <div id='burbuja_${result.recordset[i].id}' class ="direct-chat-msg" style=" padding-left: 10px;   justify-content: flex-start; padding-right:10px;display: flex;flex-wrap: nowrap;">
                                       <img class ="direct-chat-img " src="${fotoUsuario}"  >
                                         <div class ="direct-chat-text"  style="float:left;">
                                          <div class ="direct-chat-info clearfix">
                                           <span class ="direct-chat-name pull-left">${result.recordset[i].usuario} - <span style='font-weight: 900; font-size:14px'>${result.recordset[i].NombrePantalla}</span></span>
                                         </div>
                                          <span style="float:left; display:inline-block;">
                                               ${comentario}
                                           </span>
                                              <div class ="direct-chat-info clearfix">
                                         <span class ="direct-chat-timestamp pull-right">${result.recordset[i].fechaFormato}</span>
                                       </div>
                                         </div>
                                         <!-- /.direct-chat-text-->
                                       </div>
               `);
      }
    }
    //$('#contenedorChatGeneral').scrollTop($('#comentariosGenerales').height());
    if ($("#comentariosGenerales").height() > $("#contenedorChatGeneral").height()) {
      $("#tituloFijo").show();
    } else $("#tituloFijo").hide();
  }

  function nl2br (str)  {
    return str.replace(/\n/g, "<br>");
  };
  
};




export {cargarcomentariosConsolidated}