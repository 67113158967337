const axios = require("axios");
import { config } from "../../config.js";
import { loadImage } from "../loadImage.js";
import { socket } from "../realTime.js";

async function cargarcomentariosajax(idContenido, idEtiqueta) {
  try {
    configuraModal(localStorage.sociedad, idEtiqueta);
    let response = await axios.get(
      `${config.backend}/api/executiveDashboard/obtenerComentarios/${idContenido}/${idEtiqueta}/${$("#historial").is(":checked") ? false : true}`,
      {
        headers: {
          "X-API-TOKEN": localStorage.accessToken,
        },
      }
    );
    if (response.data.message) throw new Error(response.data.message);

    muestraComentarios(response.data, localStorage.sociedad, idEtiqueta);
  } catch (error) {
    alert(error);
  } finally {
  }
}

/*************************MUESTRA COMENTARIOS ***************************************************/
async function muestraComentarios(result, sociedad, idEtiqueta) {
  $(".img-circle").attr("src", localStorage.getItem("fotoUsuario"));
  $(".contenedor-loader").hide();
  $("#comentario").show();
  $("#comentario").html("");
  if (result.rowsAffected > 0) {
    for (var i = 0; i < result.rowsAffected; i++) {
      var comentario = nl2br(result.recordset[i].comentario);
      let fotoUsuario = await loadImage(result.recordset[i].idTrab)
        .then((img) => {
          return img;
        })
        .catch((dummy) => {
          return dummy;
        });
      if ((await localStorage.getItem("usuario")) == result.recordset[i].usuario) {
        $("#comentario").append(`
                                     <!-- Message to the right -->
                                     <div class="direct-chat-msg right" id='burbuja_${result.recordset[i].id}'>

                                       <!-- /.direct-chat-info-->

                                       <img class ="direct-chat-img " src="${fotoUsuario}" >

                                       <div class ="direct-chat-text" style="float:right;     background: #4a89dc;">
                                       <div class ="direct-chat-info clearfix">
                                       <span class ="direct-chat-name pull-left"><button id='close_${i}' data-idcomentario="${result.recordset[i].id}" type="button" style='font-size:large' class="close"  >&times;</button></span>
                                       </div>
                                         <span style="float:right; display:inline-block;">
                                                ${comentario}
                                         </span>
                                         <div class ="direct-chat-info clearfix">
                                         <span class ="direct-chat-timestamp pull-right">${result.recordset[i].fechaFormato}</span>
                                       </div>

                                       </div>
                                       <!-- /.direct-chat-text -->
                                     </div>
         `);
      } else {
        $("#comentario").append(`
                                         <!--Message to the left-->
                                       <div class ="direct-chat-msg" id='burbuja_${result.recordset[i].id}'>
                                       <img class ="direct-chat-img " src="${fotoUsuario}"  >
                                         <div class ="direct-chat-text"  style="float:left;">
                                          <div class ="direct-chat-info clearfix">
                                           <span class ="direct-chat-name pull-left">${result.recordset[i].usuario}</span>
                                         </div>
                                          <span style="float:left; display:inline-block;">
                                               ${comentario}
                                           </span>
                                              <div class ="direct-chat-info clearfix">
                                         <span class ="direct-chat-timestamp pull-right">${result.recordset[i].fechaFormato}</span>
                                       </div>
                                         </div>
                                         <!-- /.direct-chat-text-->
                                       </div>
               `);
      }
      $(`#close_${i}`).on("click", function () {
        eliminarComentario($(this).data("idcomentario"));
      });
    }

    var cualEtiqueta = $("#" + sociedad + "_" + idEtiqueta + " span");
    cualEtiqueta.text(result.rowsAffected);
  }
  $("#contenedor-chat").scrollTop($("#comentario").height());
  $("#txt_mensaje").focus();
}

//***********************************INSERTAR COMENTARIO *********************************************************/
async function insertarComentario(comentario, idEtiqueta, idContenido, idContenidoBase, sociedad) {
  if (comentario == null || comentario == "") {
    alert("Escribe un comentario");

    return false;
  }
  var usuario = localStorage.usuario;
  if (usuario == null) {
    alert("Es necesario LOGIN para continuar...");
    $("#myModalcomentarios").modal("hide");
    $("#myModalLogin").modal("show");
    return false;
  }

  try {
    var data = {
      usuario: usuario,
      comentario: comentario,
      idContenido: idContenido,
      idEtiqueta: idEtiqueta,
      sociedad: sociedad,
      historial: $("#historial").is(":checked") ? true : false,
      numEmpleado: localStorage.idTrab,
    };
    let response = await axios.post(`${config.backend}/api/executiveDashboard/insertarComentario`, data, {
      headers: {
        "X-API-TOKEN": localStorage.accessToken,
      }
    });
    if (response.data.message) throw new Error(response.data.message);
    mensajeEnviado(response.data, idEtiqueta, sociedad, idContenidoBase, idContenido);
  } catch (error) {
    alert(error);
  } finally {
  }
}

/*************************************** MENSAJE ENVIADO ****************************************************************/
function mensajeEnviado(result, idEtiqueta, sociedad, idContenidoBase, idContenido) {
  if (result.rowsAffected == null) return false;
  var comentario = nl2br($("#txt_mensaje").val());
  $("#comentario").append(`
        <!-- Message to the right -->
        <div class="direct-chat-msg right" >

          <!-- /.direct-chat-info-->

          <img class ="direct-chat-img " src="${localStorage.getItem("fotoUsuario")}" >

          <div class ="direct-chat-text" style="float:right;     background: #4a89dc;">
            <span style="float:right; display:inline-block;">
                   ${comentario}
            </span>
            <div class ="direct-chat-info clearfix">
            <span class ="direct-chat-timestamp pull-right">Enviando...</span>
          </div>

          </div>
          <!-- /.direct-chat-text -->
        </div>
`);
  $("#contenedor-chat").scrollTop($("#comentario").height());
  $("#txt_mensaje").focus();
  $("#txt_mensaje").val("");
  cargarcomentariosajax(idContenido, idEtiqueta);
  //Se envia la señal al servidor de SOCKETS que se ha realizado una carga de comentario
  socket.emit("cargarComentarios", idContenido, idEtiqueta, result.recordset[0].total, idContenidoBase);
}

function configuraModal(sociedad, idEtiqueta) {
  //*****mensaje antes de cargar los comentarios */
  var cualEtiqueta = $("#" + sociedad + "_" + idEtiqueta + " span");
  $(cualEtiqueta).removeClass("blinkNotifica");

  $("#tituloEnModal").html(
    $(".active:eq(1)").data("titulo") != ""
      ? $("#sociedad").text() + ' <span class="tituloContenido">' + $(".active:eq(1)").data("titulo") + "</span>"
      : "Comentarios"
  );
  $("#fotoPerfil2").attr("src", localStorage.getItem("fotoUsuario"));
  $("#nombreUsuario2").html(localStorage.getItem("nombreCompleto"));
  $("#usuario2").html(localStorage.getItem("usuario"));
  $("#myModalcomentarios").show();
  $("#consolidatedComents").hide();
  $(".carousel-inner").width("60%");
  $("#der").css("right", "35%");
  $("#botonera").css("right", "37%");
  $(".carousel-indicators").css("right", "70%");
  if (sociedad + "_" + idEtiqueta != $(".etiqueta").attr("id")) {
    $(".contenedor-loader").show();
    $("#comentario").html("");
    $("#comentario").hide();
  }

  $(".nav-stacked li").removeClass("etiqueta");
  $("#" + sociedad + "_" + idEtiqueta).addClass("etiqueta");
}

/********************************ELIMINAR COMENTARIOS ****************************************************************/
async function eliminarComentario(id) {
  const confirmar = confirm("¿Eliminar este mensaje?");

  if (confirmar == true) {
    try {
      const idContenido = $(".active:eq(1)").data("idcontenidoagregado");
      const idEtiqueta = $(".etiqueta span").attr("id");
      const historial = $("#historial").is(":checked") ? true : false;
      let response = await axios.delete(
        `${config.backend}/api/executiveDashboard/eliminarComentario/${id}/${idContenido}/${idEtiqueta}/${localStorage.usuario}/${historial}`,
        {
          headers: {
            "X-API-TOKEN": localStorage.accessToken,
          },
        }
      );
      if (response.data.message) throw new Error(response.data.message);
      mensajeEliminado(id, response.data.recordset[0].total);
    } catch (error) {
      alert(error);
    } finally {
    }

    return true;
  }
  return false;
}

//****************************MENSAJE ELIMINADO  **********************************************************************/
function mensajeEliminado(id, total) {
  $("#burbuja_" + id).remove();

  socket.emit("eliminaComentario", id, $(".etiqueta span").attr("id").toString(), localStorage.sociedad, total);
}

function resetComentarios() {
  $("#myModalcomentarios").hide();
  $("#consolidatedComents").hide();
  $(".carousel-inner").width("100%");
  $("#der").css("right", "0%");
  $("#botonera").css("right", "3%");
  $(".carousel-indicators").css("right", "0%");
  $("#carouselExampleIndicators").carousel("cycle");
}

function nl2br(str) {
  return str.replace(/\n/g, "<br>");
}

export { cargarcomentariosajax, insertarComentario, eliminarComentario, resetComentarios };
